import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { ROUTES } from '../../routes';

const PostFullFooterRightDiv = styled.div`
  flex-shrink: 0;
  margin-left: 20px;
`;

const AuthorCardButton = css`
  display: block;
  padding: 9px 16px;
  border: 1px solid var(--tertiary-light, lightgrey);
  color: var(--tertiary, grey);
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 500;
  border-radius: 20px;
  transition: all ease 0.2s;

  :hover {
    border-color: var(--links-light, lightblue);
    color: var(--links-light, lightblue);
    text-decoration: none;
  }
`;

const PostFullFooterRight = props => (
  <PostFullFooterRightDiv>
    <Link css={AuthorCardButton} to={ROUTES.AUTHOR.toUrl(props.authorId)}>
      Read More
    </Link>
  </PostFullFooterRightDiv>
);

export default PostFullFooterRight;
