import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby';

import AuthorCard from '../blog/components/AuthorCard';
import Disclaimer from '../components/Disclaimer/Disclaimer';
import Footer from '../blog/components/Footer';
import IndexLayout from '../layouts';
import PostCard from '../blog/components/PostCard';
import PostContent from '../blog/components/PostContent';
import PostFullFooter from '../blog/components/PostFullFooter';
import PostFullFooterRight from '../blog/components/PostFullFooterRight';
import ReadNextCard from '../blog/components/ReadNextCard';
import SiteNav from '../blog/components/Header/SiteNav';
import Subscribe from '../blog/components/Subscribe/Subscribe';
import Wrapper from '../blog/components/Wrapper/Wrapper';

import MetaData from '../containers/MetaData';

import { config } from '../website-config';
import { ROUTES } from '../routes';
import { inner, outer, SiteHeader, SiteMain } from '../styles/shared';
import {
  DateDivider,
  NoImage,
  PostFull,
  PostFullHeader,
  PostFullImage,
  PostFullMeta,
  PostFullMetaDate,
  PostFullTitle,
  ReadNextFeed,
} from '../styles/templates/post.styles';

const PageTemplate = ({ data, pageContext }) => {
  const { excerpt, frontmatter, htmlAst } = data.markdownRemark || {};
  const { author, date, image, imageAlt, modDate, tags, title } = frontmatter || {};
  const { height, width, images } =
    frontmatter.image?.childImageSharp?.gatsbyImageData || {};
  const { next, prev, slug } = pageContext;

  const relativeImgUrl = images?.fallback?.src || null;

  return (
    <IndexLayout className="post-template">
      <MetaData
        author={author.name}
        description={frontmatter.description || excerpt}
        height={height || ''}
        image={relativeImgUrl}
        imageAlt={imageAlt}
        published={date}
        robots="index, follow"
        tags={tags}
        title={title}
        type="article"
        url={slug}
        updated={modDate}
        width={width || ''}
      />
      <Wrapper>
        <SiteHeader css={outer}>
          <div css={inner}>
            <SiteNav />
          </div>
        </SiteHeader>
        <SiteMain useTemplate useOuter>
          <div css={inner}>
            <PostFull css={!image ? NoImage : null}>
              <PostFullHeader>
                <PostFullMeta>
                  <PostFullMetaDate>{frontmatter.userDate}</PostFullMetaDate>
                  {tags.length > 0 && (
                    <>
                      <DateDivider>|</DateDivider>
                      <Link to={ROUTES.TAGS.toUrl(tags[0])}>{tags[0]}</Link>
                    </>
                  )}
                </PostFullMeta>
                <PostFullTitle>{title}</PostFullTitle>
                <PostFullMeta>
                  {modDate ? <PostFullMetaDate>{`Last Updated: ${frontmatter.updatedDate}`}</PostFullMetaDate> : null}
                </PostFullMeta>
              </PostFullHeader>
              {image?.childImageSharp && (
                <PostFullImage>
                  <GatsbyImage style={{ height: '100%' }} image={image.childImageSharp.gatsbyImageData} alt={imageAlt} />
                </PostFullImage>
              )}
              <PostContent htmlAst={htmlAst} />
              {frontmatter.disclaimer === true ? <Disclaimer margin="55px 0 60px" /> : null}
              {/* The big email subscribe modal content */}
              {config.showSubscribe && <Subscribe title={config.title} />}

              <PostFullFooter>
                <AuthorCard author={author} />
                <PostFullFooterRight authorId={author.name} />
              </PostFullFooter>
            </PostFull>
          </div>
        </SiteMain>

        {/* Links to Previous/Next posts */}
        <aside className="read-next" css={outer}>
          <div css={inner}>
            <ReadNextFeed>
              {data.relatedPosts && <ReadNextCard tags={tags} relatedPosts={data.relatedPosts} />}

              {prev && <PostCard post={prev} />}
              {next && <PostCard post={next} />}
            </ReadNextFeed>
          </div>
        </aside>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default PageTemplate;

export const query = graphql`
  query($slug: String, $primaryTag: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      htmlAst
      excerpt
      timeToRead
      frontmatter {
        date
        description
        disclaimer
        modDate
        updatedDate: modDate(formatString: "MMMM DD, YYYY")
        userDate: date(formatString: "MMMM DD, YYYY")
        tags
        title
        imageAlt
        image {
          childImageSharp {
            gatsbyImageData(width: 3720)
          }
        }
        author {
          name
          bio
          avatar {
            children {
              ... on ImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
      }
    }
    relatedPosts: allMarkdownRemark(
      filter: { frontmatter: { tags: { in: [$primaryTag] }, draft: { ne: true } } }
      limit: 3
    ) {
      totalCount
      edges {
        node {
          id
          timeToRead
          excerpt
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
