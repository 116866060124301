import React from 'react';
import styled from 'styled-components';
import { getSrc } from "gatsby-plugin-image";

import LinkWrapper from '../../components/LinkWrapper/LinkWrapper';

import { ROUTES } from '../../routes';

import { AuthorProfileImage } from '../../styles/shared';

const AuthorCardSection = styled.section`
  display: flex;
`;

const AuthorCardName = styled.h4`
  margin: 8px 0 2px 0;
  padding: 0;
  font-size: 2rem;

  a {
    color: var(--black-lightened, #000);
    font-weight: 700;
  }

  a:hover {
    text-decoration: none;
  }
`;

const AuthorCardContent = styled.section`
  p {
    margin: 0;
    color: var(--tertiary, grey);
    line-height: 1.3em;
  }
`;

const AuthorCard = ({ author }) => {
  return (
    <AuthorCardSection>
      <AuthorProfileImage src={getSrc(author.avatar.children[0])} alt={author.name} />
      <AuthorCardContent>
        <AuthorCardName>
          <LinkWrapper display="inline" to={ROUTES.AUTHOR.toUrl(author.name)}>{author.name}</LinkWrapper>
        </AuthorCardName>
        {author.bio ? (
          <p>{author.bio}</p>
        ) : (
          <p>
            <LinkWrapper to={ROUTES.AUTHOR.toUrl(author.name)}>Read more posts by this author.</LinkWrapper>
          </p>
        )}
      </AuthorCardContent>
    </AuthorCardSection>
  );
};

export default AuthorCard;
