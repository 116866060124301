import styled from 'styled-components';

export const SubscribeFormSection = styled.section`
  margin: 4.5em 0 3em;
  padding: 6vw 7vw 6.5vw;
  border: 1px solid var(--orange-light, orange);
  text-align: center;
  background: var(--orange-very-light, navajowhite);
  border-radius: 7px;

  form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 420px;
  }

  .form-group {
    flex-grow: 1;
  }
  @media (max-width: 650px) {
    p {
      font-size: 1.6rem;
    }
  }
`;

export const SubscribeFormTitle = styled.h4`
  margin: 0 0 8px 0;
  padding: 0;
  color: var(--teriary-dark, black);
  font-size: 3.5rem;
  line-height: 1;
  font-weight: 700;
  @media (max-width: 650px) {
    font-size: 2.4rem;
  }
`;

export const SubscribeMessage = styled.p`
  margin-bottom: 1em;
  color: var(--orange-black, maroon);
  font-size: 2.2rem;
  line-height: 1.55em;
  letter-spacing: 0.2px;
`;

export const SubscribeFooter = styled.p`
  margin: 2em 0 0;
  font-size: 1.5rem;
  font-style: italic;
  color: var(--orange-black, maroon);
`;
