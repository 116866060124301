import React from 'react';

import SubscribeForm from './SubscribeForm';
import LinkWrapper from '../../../components/LinkWrapper/LinkWrapper';

import {
  SubscribeFooter,
  SubscribeFormSection,
  SubscribeFormTitle,
  SubscribeMessage,
} from './Subscribe.styles';

const Subscribe = ({ title }) => {
  return (
    <SubscribeFormSection>
      <SubscribeFormTitle>Subscribe to {title}</SubscribeFormTitle>
      <SubscribeMessage>Are you a developer interested in building your own SaaS business?</SubscribeMessage>
      <SubscribeMessage>
        Get exclusive, behind-the-scenes insights (and first access priority) on my journey and process in
        building a SaaS business from scratch!
      </SubscribeMessage>
      <SubscribeMessage>
        Not sure if this is for you? Then check out my{' '}
        <LinkWrapper 
          ariaLabel="David Nowak's YouTube Channel"
          color="midnightblue"
          decor="unset"
          to="https://www.youtube.com/channel/UCuFfnF3p1AWLTyZDxegOPzg"
        >
          Entrepreneurial Dreams: Build a SaaS Business in 12 Months Challenge.
        </LinkWrapper>
      </SubscribeMessage>
      <SubscribeForm />
      <SubscribeFooter>
        Quality content! No SPAM and we will NEVER sell your data or email... guaranteed!
      </SubscribeFooter>
    </SubscribeFormSection>
  );
};

export default Subscribe;
